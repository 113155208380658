<template>
	<div v-if="copyRight.icp || copyRight.gov_url" style="display: flex;
    align-items: center;height: 40px;width: 100%;
    justify-content: center;padding-bottom: 20px;"><a class="aLink" style="color: #838383;" href="https://beian.miit.gov.cn/">{{copyRight.icp}}</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
	
	<a v-if="copyRight.gov_url" style="color: #838383;display: flex;
    align-items: center;
}" href="#" @click="gov_url(copyRight.gov_url)"><el-image style="width: 20px;height: 20px;margin-left: 25px;"  v-if="copyRight.gov_record" src="https://168.gimp.cn/public/static/img/jgwab.png"  fit="contain"></el-image>{{copyRight.gov_record}}</a></div>
</template>

<script>
    import { copyRight } from "@/api/website"
    import { mapGetters } from "vuex"
    export default {
        props: {},
        data() {
            return {}
        },
        computed: {},
        created() {
            this.$store.dispatch("site/copyRight")
        },
        mounted() {},
        watch: {},
        methods: {
			gov_url(url){
				window.open(url)
			}
		},
        computed: {
            ...mapGetters(["copyRight", "siteInfo"])
        }
    }
</script>

<style scoped lang="scss">
	a:hover{
		color: #f30213 !important;
	}
    .footer-bottom {
        width: 100%;
        height: 85px;
        margin: 0 auto;
        padding: 20px 0;
		.foot-link {
			padding:0 10px;
			border-right:1px solid #b3b3b3;
			height: 14px; 
			line-height: 14px;
			margin:120px 0 10px 0;
			color: #b3b3b3;
		}
		.footer-link {
			padding:0 10px;
			border-right:1px solid #b3b3b3;
			margin-bottom: 10px;
			p {
				
			}
		}
        p {
            margin: 0;
            width: 100%;
            box-sizing: border-box;
            text-align: center;
			
            img {
                width: 20px;
                height: 20px;
                margin-right: 5px;
            }
            .footer-link, .el-link.el-link--default {
               color: #b3b3b3 !important;
            }
			.footer-link:hover, .el-link.el-link--default:hover {
				color: $base-color;
			}
        }
        .site-info {
            // display: flex;
            // justify-content: center;
            // align-items: center;
			text-align: center;
            p {
                width: auto;
                margin: 0 10px;

                i {
                    vertical-align: bottom;
                    margin-right: 5px;
                }
            }
        }
    }
</style>
